import { SystemProps, x } from '@xstyled/styled-components';

import {
  LANDING_LAUNCH_BANNER_EMOJI,
  LANDING_LAUNCH_BANNER_MESSAGE,
  LANDING_LAUNCH_BANNER_URL,
} from '../../../config/constants';
import { Emoji } from '../../common/components/Emoji';
import { Text } from '../../common/components/Text';

export const LandingLaunchBanner: React.FC<SystemProps> = (props) => {
  if (
    LANDING_LAUNCH_BANNER_MESSAGE === undefined ||
    LANDING_LAUNCH_BANNER_URL === undefined
  )
    return null;

  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="yellow.75"
      py={1}
      px={3}
      borderRadius="sm"
      {...props}
    >
      <x.a
        textDecoration="none"
        color="inherit"
        href={LANDING_LAUNCH_BANNER_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <x.div
          display="flex"
          flexDirection="row"
          spaceX={3}
          alignItems="center"
          p={{ _: 8, md: 0 }}
          w="full"
          boxSizing="border-box"
          justifyContent="center"
        >
          {LANDING_LAUNCH_BANNER_EMOJI && (
            <Emoji
              emojiUnicode={LANDING_LAUNCH_BANNER_EMOJI}
              size={24}
              // mr={5}
              // mb={0}
            />
          )}
          <Text as="span" variant="md-semibold" color="yellow.300">
            {LANDING_LAUNCH_BANNER_MESSAGE}
          </Text>
          <Text as="span" variant="md-semibold" color="yellow.300">
            →
          </Text>
        </x.div>
      </x.a>
    </x.div>
  );
};
