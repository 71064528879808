/**
 * Marketing theme extends the main product theme for now
 */
import { theme as baseTheme } from '@frond/shared';

export const marketingTheme = {
  ...baseTheme,

  screens: {
    _: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },

  states: {
    short: '@media (max-height: 850px)',
  },

  shadows: {
    default: '2px 2px 30px rgba(0, 0, 0, 0.18)',
    glossyModal: '2px 2px 30px rgba(0, 0, 0, 0.18)',
  },
};
