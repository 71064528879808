import { x } from '@xstyled/styled-components';

import { Text } from '../../common/components/Text';

type LandingLoginButtonProps = {
  variant: 'root' | 'hello';
  disabled?: boolean;
  attemptSignIn?: () => void;
};

export const LandingLoginButton: React.FC<LandingLoginButtonProps> = ({
  variant,
  disabled = false,
  attemptSignIn,
}) => {
  return (
    <>
      <x.button
        onClick={attemptSignIn}
        px={4}
        py={2}
        border="none"
        color={disabled ? 'gray.300' : 'inherit'}
        cursor={{ _: 'pointer', disabled: 'default' }}
        display="block"
        borderRadius="md"
        textDecoration="none"
        backgroundColor={
          variant === 'hello'
            ? 'white'
            : disabled
              ? 'gray.50'
              : {
                  _: 'gray.100',
                  hover: 'gray.200',
                  active: 'gray.100',
                  focus: 'gray.200',
                }
        }
        alignSelf="flex-start"
        disabled={disabled}
        transition
        transitionDuration="faster"
        transitionTimingFunction="linear"
      >
        <Text as="span" variant="sm-semibold">
          Sign in
        </Text>
      </x.button>
    </>
  );
};
