import { routes } from '@frond/shared';
import { NextRouter, useRouter } from 'next/router';
import { FC, useState } from 'react';

import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { Button } from '../../common/components/Button';
import { LandingLoginButton } from './LandingLoginButton';

export const LandingNavAuthButtons: FC = () => {
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
  const router = useRouter();

  const handleAttemptSignIn = (destinationPath?: string) => {
    setPostLoginRedirect(router, destinationPath);
    setSignInModalIsOpen(true);
  };

  const handleDismissSignIn = () => {
    stripPostLoginRedirect(router);
    setSignInModalIsOpen(false);
  };

  return (
    <>
      <LandingLoginButton
        variant="root"
        attemptSignIn={() => handleAttemptSignIn()}
      />
      <Button
        variant="primary"
        label="Create community"
        onClick={() => {
          handleAttemptSignIn(routes.groups.organizationsNew());
        }}
        size="sm"
      />
      <SignInModal isOpen={signInModalIsOpen} onDismiss={handleDismissSignIn} />
    </>
  );
};

const setPostLoginRedirect = (router: NextRouter, path?: string) => {
  if (!router.query.callbackUrlPath && path) {
    router.push({ query: { callbackUrlPath: path, ...router.query } });
  }
};

const stripPostLoginRedirect = (router: NextRouter) => {
  const query = { ...router.query };
  delete query.callbackUrlPath;
  router.push({ query });
};
