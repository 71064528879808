import { LayoutGroup, motion } from 'framer-motion';
import { FC } from 'react';

import { Text } from '../../common/components/Text';
import { isChromatic } from '../../common/utils/isChromatic';
import {
  LandingLocationTicker,
  LandingLocationTickerProps,
} from './LandingLocationTicker';

type LandingMadeInProps = {
  tickerVariant: LandingLocationTickerProps['variant'];
};

export const LandingMadeIn: FC<LandingMadeInProps> = ({ tickerVariant }) => {
  return (
    <LayoutGroup>
      <Text
        as="span"
        variant={tickerVariant === 'monochromeMd' ? 'md-semibold' : 'sm-medium'}
        display="flex"
        justifyContent={{ _: 'center', lg: 'initial' }}
        alignItems="center"
        data-chromatic="ignore"
      >
        <motion.span layout>Made in</motion.span>
        <motion.span layout="position">
          <LandingLocationTicker
            animated={!isChromatic()}
            variant={tickerVariant}
          />
        </motion.span>
        <motion.span layout>
          {`by the `}
          <Text
            as="span"
            variant={{
              _:
                tickerVariant === 'monochromeMd'
                  ? 'md-semibold'
                  : 'sm-semibold',
              lg: 'sm-semibold',
            }}
            color="purple.300"
          >
            Frond
          </Text>
          {` team`}
        </motion.span>
      </Text>
    </LayoutGroup>
  );
};
