import { x } from '@xstyled/styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { Text } from '../../common/components/Text';

const TICK_INTERVAL = 3000;
const CITIES = ['Berlin', 'Waterloo', 'San Francisco', 'Barcelona', 'New York'];

const variants = {
  default: {
    bg: 'marketing.violet.300',
    color: 'marketing.cream.200',
  },
  monochrome: {
    bg: 'gray.500',
    color: 'white',
  },
  monochromeMd: {
    bg: 'gray.500',
    color: 'white',
  },
} as const;

export type LandingLocationTickerProps = {
  animated: boolean;
  variant?: keyof typeof variants;
};

export const LandingLocationTicker: React.FC<LandingLocationTickerProps> = ({
  animated,
  variant,
}) => {
  const [city, setCity] = useState(CITIES[0]);
  const variantStyles = variants[variant || 'default'];

  useEffect(() => {
    const handleTick = () => {
      const index = CITIES.indexOf(city);
      const nextCity = CITIES[index + 1] || CITIES[0];

      setCity(nextCity);
    };

    animated && setTimeout(handleTick, TICK_INTERVAL);
  }, [city, animated]);

  return (
    <x.span
      py={2.5}
      pl={3}
      pr={4}
      mx={2}
      display="flex"
      color={variantStyles.color}
      position="relative"
    >
      <x.span
        as={motion.span}
        layout
        bg={variantStyles.bg}
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        initial={{ borderRadius: '25px' }}
      />
      <x.svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        verticalAlign="sub"
        mr={2}
        zIndex="1"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0208 8.30244C17.0208 5.63051 16.0307 3.57393 14.5031 2.1909C12.9871 0.818466 10.9833 0.145761 8.99996 0.145752C7.01669 0.145743 5.01278 0.818448 3.49687 2.19088C1.96926 3.57391 0.979126 5.63049 0.979126 8.30244C0.979126 11.8457 3.08764 14.8013 5.02155 16.7778L5.06463 16.8218H5.06465C5.67629 17.4471 6.21015 17.9928 6.79349 18.3667C7.432 18.7759 8.11107 18.9723 9.00014 18.9723C9.88931 18.9723 10.5684 18.7758 11.2068 18.3666C11.7902 17.9927 12.324 17.447 12.9357 16.8218V16.8217L12.9788 16.7777C14.9125 14.8011 17.0208 11.8456 17.0208 8.30244ZM11.75 8.16659C11.75 9.68541 10.5188 10.9166 8.99996 10.9166C7.48113 10.9166 6.24996 9.68541 6.24996 8.16659C6.24996 6.64781 7.48113 5.41659 8.99996 5.41659C10.5188 5.41659 11.75 6.64781 11.75 8.16659Z"
          fill="currentColor"
        />
      </x.svg>
      <AnimatePresence mode="wait">
        <motion.div
          key={city}
          initial={{ translateY: '-1rem' }}
          animate={{
            translateY: 0,
            transition: {
              type: 'spring',
              damping: 25,
              stiffness: 400,
            },
          }}
          exit={{
            translateY: '2rem',
            transition: {
              delay: TICK_INTERVAL * 0.0006,
              type: 'tween',
            },
          }}
        >
          <Text as="span" variant="md" lineHeight="1">
            {city}
          </Text>
        </motion.div>
      </AnimatePresence>
    </x.span>
  );
};
