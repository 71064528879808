import { routes, theme } from '@frond/shared';
import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import styled, { css } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import React from 'react';

import {
  OrganizationQuery,
  ViewerQuery,
} from '../../../../generated/types-and-hooks';
import { Avatar } from '../components/Avatar';
import { ButtonProps } from '../components/Button';
import { Link } from '../components/Link';
import { MenuList } from '../components/MenuList';
import { NextLink } from '../components/NextLink';
import { Text } from '../components/Text';
import analytics from '../utils/analytics';
import { rightAlign } from '../utils/position';
import { getUserDisplayName, getUserOrganizationName } from '../utils/user';

export interface UserAccountButtonProps extends ButtonProps {
  /**
   * User object
   */
  user: ViewerQuery['viewer'];

  /**
   * Organization object
   */
  organization: OrganizationQuery['organization'];

  /**
   * Only provide the signout option
   */
  navVariant?: 'signOutOnly';

  /**
   * Callback for click on Sign Out
   */
  onSignOutClick: () => void;
  onCommunitiesClick?: () => void;
}

const AccountMenuWrapper = styled.div<{
  hasOrganizationName: boolean;
}>`
  display: flex;
  flex-direction: row;

  /* Undefined size in theme */
  min-width: 221px;
  padding: 6;

  ${(p) =>
    p.hasOrganizationName &&
    css`
      padding-bottom: 14px;
    `}

  border-bottom: default;
  border-color: gray.100;
`;

const AccountMenuTextWrapper = styled.div`
  margin-left: 3;
`;

const StyledText = styled(Text)`
  color: gray.300;
`;

const StyledMenuList = styled(MenuList)<{
  hasOrganizationName: boolean;
}>`
  border: default;
  border-color: gray.100;
  box-shadow: ${theme.shadows.sm};

  [data-reach-menu-items] {
    ${(p) =>
      p.hasOrganizationName &&
      css`
        padding-top: 14px;
      `}
  }
`;

const MenuLinkItem = styled(MenuItem)`
  padding: 2 0;

  &:hover {
    color: brand.300;
  }
`;

export const UserAccountButton: React.FC<UserAccountButtonProps> = ({
  user,
  organization,
  navVariant,
  onCommunitiesClick,
  ...props
}) => {
  const router = useRouter();

  const avatar = (
    <Avatar
      size="m-2"
      imageId={user.profileImageId}
      avatar={user.avatar}
      bgColor={user.avatarBgColor}
    />
  );

  const wrappedAvatar =
    navVariant === 'signOutOnly' ? (
      avatar
    ) : (
      <NextLink
        styleInheritColor
        href={routes.groups
          .organization(organization?.shortId || '')
          .person(user.username)}
        metadata={{ event: analytics.events.NAV_LINK, context: 'avatar' }}
      >
        {avatar}
      </NextLink>
    );

  const organizationName = organization
    ? getUserOrganizationName(organization)
    : '';

  const hasOrganizationName = organizationName !== '';

  return (
    <>
      <Menu>
        <MenuButton
          as="span"
          onClick={() => {
            analytics.logEvent(analytics.events.USER_MENU_OPEN);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Avatar
            size="m"
            avatar={user.avatar}
            bgColor={user.avatarBgColor}
            imageId={user.profileImageId}
          />
        </MenuButton>
        <StyledMenuList
          hasOrganizationName={hasOrganizationName}
          position={rightAlign}
          heading={
            <AccountMenuWrapper hasOrganizationName={hasOrganizationName}>
              {wrappedAvatar}
              <AccountMenuTextWrapper>
                <Text variant="sm-medium">{getUserDisplayName(user)}</Text>
                <StyledText variant="sm">{user.email}</StyledText>
                {hasOrganizationName && (
                  <Text variant="sm-medium" color="yellow.300" mt={2}>
                    {organizationName}
                  </Text>
                )}
              </AccountMenuTextWrapper>
            </AccountMenuWrapper>
          }
        >
          {navVariant === 'signOutOnly' ? (
            <>
              {onCommunitiesClick && (
                <MenuItem onSelect={onCommunitiesClick}>
                  <Text variant="md">Communities</Text>
                </MenuItem>
              )}
              <MenuItem
                onSelect={() => {
                  analytics.logEvent(analytics.events.USER_MENU_SELECT, {
                    option: 'signout',
                  });
                  props.onSignOutClick();
                }}
              >
                <Text variant="md">Sign out</Text>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuLinkItem
                onSelect={() => {
                  analytics.logEvent(analytics.events.USER_MENU_SELECT, {
                    option: 'profile',
                  });
                }}
              >
                <NextLink
                  styleInheritColor
                  href={routes.groups
                    .organization(organization?.shortId || '')
                    .person(user.username)}
                >
                  <Text as="span" variant="sm-medium">
                    View profile
                  </Text>
                </NextLink>
              </MenuLinkItem>
              <MenuLinkItem
                onSelect={() => {
                  router.push(routes.settings.account());

                  analytics.logEvent(analytics.events.USER_MENU_SELECT, {
                    option: 'settings',
                  });
                }}
              >
                <Text as="span" variant="sm-medium">
                  Settings
                </Text>
              </MenuLinkItem>
              <MenuLinkItem
                onSelect={() => {
                  analytics.logEvent(analytics.events.USER_MENU_SELECT, {
                    option: 'faq',
                  });
                }}
              >
                <Link
                  styleInheritColor
                  target="_blank"
                  href="https://www.notion.so/frondcom/Frond-FAQ-2f87fb17efc44ce7a38368567db8cb37"
                >
                  <Text as="span" variant="sm-medium">
                    FAQ
                  </Text>
                </Link>
              </MenuLinkItem>
              <MenuItem
                onSelect={() => {
                  analytics.logEvent(analytics.events.USER_MENU_SELECT, {
                    option: 'signout',
                  });
                  props.onSignOutClick();
                }}
              >
                <Text as="span" variant="sm-medium">
                  Sign out
                </Text>
              </MenuItem>
            </>
          )}
        </StyledMenuList>
      </Menu>
    </>
  );
};
