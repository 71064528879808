import { FEEDBACK_EMAIL_ADDRESS, routes } from '@frond/shared';
import { SystemProps, x } from '@xstyled/styled-components';
import React from 'react';

import { FROND_X_URL } from '../../../config/constants';
import { FrondLogo } from '../../common/components/FrondLogo';
import { Icon } from '../../common/components/Icon';
import { NextLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import { LandingMadeIn } from './LandingMadeIn';

const FOOTER_LINKS = [
  { href: routes.marketing.community(), label: 'Community' },
  { href: routes.blog.index(), label: 'Blog' },
  { href: routes.marketing.faq(), label: 'FAQ' },
  { href: routes.marketing.changelog(), label: 'Changelog' },
  { href: routes.marketing.roadmap(), label: 'Roadmap' },
  { href: routes.marketing.privacy(), label: 'Privacy' },
  { href: routes.marketing.mediakit(), label: 'Media kit', canHide: true },
  { href: `mailto:${FEEDBACK_EMAIL_ADDRESS}`, label: 'Get in touch' },
];

const FooterLinksHorizontal: React.FC = () => {
  return (
    <x.div display="flex" spaceX="8" alignItems="center">
      {FOOTER_LINKS.map((link, index) => {
        return (
          <x.div
            key={index}
            display={link.canHide ? { _: 'none', xl: 'block' } : 'block'}
          >
            <NextLink href={link.href} styleInheritColor>
              <Text
                variant="sm-semibold"
                color={{ hover: 'purple.400' }}
                transition
                transitionDuration="fast"
              >
                {link.label}
              </Text>
            </NextLink>
          </x.div>
        );
      })}
    </x.div>
  );
};

const FooterLinksVertical: React.FC = () => {
  return (
    <x.div display="flex" flexDirection="column" spaceY={4} pt={8}>
      {FOOTER_LINKS.map((link, index) => (
        <NextLink key={index} href={link.href} styleInheritColor>
          <Text variant="md">{link.label}</Text>
        </NextLink>
      ))}
    </x.div>
  );
};

export const LandingFooter: React.FC<SystemProps> = ({ ...props }) => {
  return (
    <>
      {/* Large screen footer */}
      <x.div
        display={{ _: 'none', lg: 'flex' }}
        justifyContent="space-between"
        alignItems="center"
        py={10}
        w="full"
        {...props}
      >
        <LandingMadeIn tickerVariant="monochrome" />
        <FooterLinksHorizontal />
      </x.div>

      {/* Small screen footer */}
      <x.div
        display={{ _: 'flex', lg: 'none' }}
        flexDirection="column"
        w="full"
        {...props}
      >
        <x.div bg="gray.50" px="8" py="10">
          <x.div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <x.div w={100}>
              <FrondLogo horizontal />
            </x.div>
            <NextLink href={FROND_X_URL} styleInheritColor>
              <Icon name="app-twitter" size="6" />
            </NextLink>
          </x.div>
          <FooterLinksVertical />
        </x.div>
        <x.div py={8}>
          <LandingMadeIn tickerVariant="monochrome" />
        </x.div>
      </x.div>
    </>
  );
};
