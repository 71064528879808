import { routes } from '@frond/shared';
import { SystemProps, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { ViewerQuery } from '../../../../generated/graphql-request-api-sdk';
import { FrondLogo } from '../../../modules/common/components/FrondLogo';
import { useSignOut } from '../../../modules/common/hooks/useApollo';
import { UserAccountButton } from '../../../modules/common/layout/UserAccountButton';
import { Button } from '../../common/components/Button';
import { FrondLogoMinimal } from '../../common/components/FrondLogoMinimal';
import { Icon, IconNames } from '../../common/components/Icon';
import { NextLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import { LandingLaunchBanner } from './LandingLaunchBanner';
import { LandingNavAuthButtons } from './LandingNavAuthButtons';

const NAV_LINKS = [
  { href: routes.marketing.features(), label: 'Features', external: false },
  { href: routes.marketing.pricing(), label: 'Pricing', external: false },
  { href: routes.blog.index(), label: 'Blog', external: false },
  { href: routes.marketing.about(), label: 'About', external: false },
];

const NavLinkHorizontal: React.FC<PropsWithChildren<{ href: string }>> = ({
  href,
  children,
}) => {
  return (
    <NextLink href={href} styleInheritColor>
      <Text
        variant="sm-semibold"
        color={{ hover: 'purple.400' }}
        transition
        transitionDuration="fast"
      >
        {children}
      </Text>
    </NextLink>
  );
};

const NavLinkHorizontalExternal: React.FC<
  PropsWithChildren<{ href: string }>
> = ({ href, children }) => {
  return (
    <Text
      variant="sm-semibold"
      color={{ hover: 'purple.400' }}
      transition
      transitionDuration="fast"
    >
      <x.a
        textDecoration="none"
        color="inherit"
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </x.a>
    </Text>
  );
};

const NavLinksHorizontal: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <x.div display="flex" spaceX="8" alignItems="center">
      {children}
    </x.div>
  );
};

const NavLinkMenu: React.FC<PropsWithChildren<{ href: string }>> = ({
  href,
  children,
}) => {
  return (
    <NextLink href={href} styleInheritColor>
      <Text variant="lg">{children}</Text>
    </NextLink>
  );
};

const NavMenu: React.FC<PropsWithChildren<{ isOpen: boolean }>> = ({
  isOpen,
  children,
}) => {
  return (
    <x.div
      display={isOpen ? 'block' : 'none'}
      position="fixed"
      top={0}
      left={0}
      w="100vw"
      h="100vh"
      bg="white"
      px={8}
      py={4}
      boxSizing="border-box"
      zIndex={10}
    >
      {children}
    </x.div>
  );
};

const NavIconButton: React.FC<{ name: IconNames; onClick: () => void }> = ({
  name,
  onClick,
}) => {
  return (
    <x.div
      onClick={() => onClick()}
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Icon name={name} size="6" />
    </x.div>
  );
};

export const LandingNav: React.FC<
  SystemProps & {
    user?: ViewerQuery['viewer'];
    noLogoOnMobile?: boolean;
    redirectOnSignout?: boolean;
  }
> = ({ user, noLogoOnMobile, redirectOnSignout = true, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const signOut = useSignOut();

  useEffect(() => {
    const handleHashChangeStart = () => setIsOpen(false);

    router.events.on('hashChangeStart', handleHashChangeStart);
    return () => {
      router.events.off('hashChangeStart', handleHashChangeStart);
    };
  }, [router.events]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      {/* Large screen nav */}
      <x.div
        display={{ _: 'none', lg: 'flex' }}
        minHeight={11}
        justifyContent="space-between"
        alignItems="center"
        px={2.5}
        py={{ _: 0, sm: 10 }}
        bg="white"
        {...props}
      >
        <NextLink href="/" styleInheritColor>
          <x.div w={129}>
            <FrondLogo horizontal />
          </x.div>
        </NextLink>

        <NavLinksHorizontal>
          <x.div display={{ _: 'none', xl: 'block' }}>
            <LandingLaunchBanner />
          </x.div>

          {NAV_LINKS.map(({ href, label, external }, index) =>
            external ? (
              <NavLinkHorizontalExternal key={index} href={href}>
                {label}
              </NavLinkHorizontalExternal>
            ) : (
              <NavLinkHorizontal key={index} href={href}>
                {label}
              </NavLinkHorizontal>
            )
          )}

          <x.div display="flex" spaceX={3}>
            {!user && <LandingNavAuthButtons />}
            {user && (
              <UserAccountButton
                navVariant="signOutOnly"
                onCommunitiesClick={() => {
                  router.push(routes.groups.organizations());
                }}
                onSignOutClick={() => {
                  // counterintuitively, if we want to override the default redirect,
                  // we need to set the redirect to the current page
                  const redirect = redirectOnSignout
                    ? undefined
                    : window.location.href;

                  signOut(redirect);
                }}
                user={user}
                organization={undefined}
              />
            )}
          </x.div>
        </NavLinksHorizontal>
      </x.div>

      {/* Small screen nav */}
      <x.div
        display={{ _: 'flex', lg: 'none' }}
        minH={16}
        py={4}
        bg="white"
        {...props}
      >
        <x.div
          display="flex"
          justifyContent={noLogoOnMobile ? 'flex-end' : 'space-between'}
          alignItems="center"
          w="full"
        >
          {!noLogoOnMobile && (
            <NextLink href="/" styleInheritColor>
              <x.div w={10}>
                <FrondLogoMinimal />
              </x.div>
            </NextLink>
          )}
          <NavIconButton name="menu" onClick={() => setIsOpen(true)} />
        </x.div>

        <NavMenu isOpen={isOpen}>
          <x.div display="flex" flexDirection="column" spaceY={4} pb={12}>
            <x.div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              h={16}
            >
              <NextLink href="/" styleInheritColor>
                <FrondLogoMinimal w={10} />
              </NextLink>
              <NavIconButton name="close" onClick={() => setIsOpen(false)} />
            </x.div>

            {NAV_LINKS.map(({ href, label }, index) => (
              <NavLinkMenu key={index} href={href}>
                {label}
              </NavLinkMenu>
            ))}
            <NavLinkMenu href={routes.signin()}>Sign in</NavLinkMenu>
          </x.div>

          <Button
            variant="primary"
            label="Create community"
            href={routes.groups.organizationsNew()}
            size="sm"
          />
        </NavMenu>
      </x.div>
    </>
  );
};
